<template>
  <section class="my-4">
    <v-row
      class="mb-2"
      v-if="
        haveRoles([
          'ROLE_UACI',
          'ROLE_DIRECCION_COMPRAS',
          'ROLE_TECNICO_DIRECCION_COMPRAS',
        ]) && $route.name == 'pac-procesos-configuracion-v1' || $route.name == 'pac-procesos-etapas-v1'
        && !haveRoles(['ROLE_PROVEEDOR']) &&
        ![5, 6, 8].includes(proceso?.id_estado_proceso)
      "
    >
      <v-col cols="12" md="4" sm="6">
        <v-text-field
          :error-messages="nombreArchivoError"
          @blur="$v.nombre.$touch()"
          @input="$v.nombre.$touch()"
          label="Nombre del documento *"
          outlined
          v-model="nombre"
          maxlength="75"
        />
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-file-input
          :error-messages="archivoError"
          @blur="$v.archivo.$touch()"
          @input="$v.archivo.$touch()"
          accept="application/pdf"
          label="Archivo *"
          outlined
          placeholder="Seleccione un archivo"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          v-model="archivo"
        />
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-btn
          large
          class="mt-2 text-no-style"
          color="secondary"
          @click="guardarArchivo"
        >
          <v-icon color="white">mdi-content-save</v-icon>
          <span class="pl-3">Guardar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="mt-4 mb-4"
      :headers="headers"
      :items="items"
    >
    <template v-slot:[`item.fecha_carga`]="{ item }">
        {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
              @click="descargarArchivo(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar archivo</span>
        </v-tooltip>

        <v-tooltip right v-if="item.id_usuario === userInfo.user.id && [1, 11].includes(proceso?.id_seguimiento_proceso)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
              @click="(itemSelected = item), (showModalDeleteProcessDocument = true)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar archivo</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ConfirmationDialogComponent
      :show="showModalDeleteProcessDocument"
      :title="`¿Desea eliminar el documento seleccionado?`"
      message="Esta acción es irreversible"
      @close="(showModalDeleteProcessDocument = false), (itemSelected = null)"
      @confirm="eliminarArchivo()"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { helpers, required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "DocProveedorComponent",

  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  props: {
    proceso: {
      type: Object,
      default: null
    }
  },

  validations: {
    nombre: {
      required,
      txtField,
    },
    archivo: {
      required,
      fileNameValid,
      fileTypeValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },

  data: () => ({
    items: [],
    archivo: null,
    nombre: null,
    itemSelected: null,
    showModalDeleteProcessDocument: false,
  }),

  computed: {
    ...mapState(["userInfo"]),

    nombreArchivoError() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.nombre.txtField &&
        errors.push(
          "El nombre del archivo no es válido, solo se permiten letras, números y guiones"
        );
      return errors;
    },
    archivoError() {
      const errors = [];
      if (!this.$v.archivo.$dirty) return errors;
      !this.$v.archivo.required && errors.push("El archivo es requerido");
      !this.$v.archivo.fileTypeValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.archivo.fileNameValid &&
        errors.push("El archivo debe tener un nombre válido");
      !this.$v.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },

    headers () {
      if (this.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])) {
        return [
          {
            text: "Documento",
            value: "nombre_documento",
            align: "center",
          },
          {
            text: "Fecha de carga",
            value: "fecha_carga",
            align: "center",
          },
          {
            text: "Subido por",
            value: "Usuario.email",
            align: "center",
          },
          {
            text: "Acciones",
            value: "acciones",
            align: "center",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "Documento",
            value: "nombre_documento",
            align: "center",
          },
          {
            text: "Fecha de carga",
            value: "fecha_carga",
            align: "center",
          },
          {
            text: "Acciones",
            value: "acciones",
            align: "center",
            sortable: false,
          },
        ]
      }
    }
  },

  methods: {
    async guardarArchivo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let formData = new FormData();
        formData.append("documento", this.archivo);
        formData.append("nombre", this.nombre);
        formData.append("id_proceso", this.$route.params.idProceso);
        formData.append("id_tipo_documento", 2000); // cambiar por el id del tipo de documento y no estático
        formData.append("folder", "documentos_hito");

        const response =
          await this.services.PacProcesos.guardarDocumentosProceso(
            formData
          ).catch(() => {

          });

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado correctamente",
          });
          this.getDocumentosEtapa();
          this.archivo = null;
          this.nombre = null;
          this.$v.$reset();
        }


      }
    },

    async getDocumentosEtapa() {


      const response = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        2000 // cambiar por el id del tipo de documento y no estático
      ).catch(() => {

      });

      if (response.status === 200) {
        this.items = response.data;
      }


    },

    async descargarArchivo(item) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },

    async eliminarArchivo() {

      const response = await this.services.PacProcesos.deleteProcessDocument(this.itemSelected.id)
      
      if (response?.status === 200) {
        this.showModalDeleteProcessDocument = false;
        this.itemSelected = null;
        this.getDocumentosEtapa();

        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se ha eliminado el documento seleccionado",
        });
      }


    }
  },

  created() {
    this.getDocumentosEtapa();
  },
};
</script>